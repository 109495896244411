import React from 'react'
import './singleCardLayout.scss'
import Layout from '../layout'

interface Props {
  readonly title: string
  readonly headerImageUrl: string
  readonly children: React.ReactNode
}

const SingleCardLayout: React.FC<Props> = ({title, headerImageUrl, children}) => (
  <Layout title={title}>
    <section className={'hero is-link is-medium'}>
      <div className={'hero-body has-text-centered'}
           style={{
             backgroundImage: `url(${headerImageUrl})`,
             backgroundRepeat: 'no-repeat',
             backgroundPosition: 'center top',
             backgroundSize: 'cover',
           }}>
        <p className={'title is-1'}>{title}</p>
      </div>
    </section>
    <section className={'articles column is-8 is-offset-2'}>
      <div className='card article'>
        <div className={'card-content'}>
          {children}
        </div>
      </div>
    </section>
  </Layout>
)

export default SingleCardLayout

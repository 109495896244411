import React from 'react'
import SingleCardLayout from '../../layouts/singleCardLayout'
// @ts-ignore
import bgImg from '../../assets/images/impressum-bg.jpg'

const ImprintPage = () => {
  return (
    <SingleCardLayout title={`Impressum`} headerImageUrl={bgImg}>
      <h4 className={'title is-4'}>Red Oak Consulting & Services for IT GmbH & Co. KG</h4>
      <p>
        Am Westbahnhof 11<br />
        38118 Braunschweig<br />
      </p>

      <p>
        E-Mail: <a href={'mailto:kontakt@red-oak-consulting.com'}>kontakt@red-oak-consulting.com</a>
      </p>
      <h5 className={'title is-5'}>Vertreten durch</h5>
      <p>
        Komplementär Red Oak Management GmbH<br />
        Geschäftsführer Matthias Geske<br />
        Geschäftsführer Michael Blank<br />
      </p>
      <h5 className={'title is-5'}>Registereintrag</h5>

      <p>
        Eingetragen im Handelsregister.<br />
        Registergericht: Braunschweig<br />
        Registernummer: HRA202077<br />
      </p>
      <h5 className={'title is-5'}>Umsatzsteuer-ID</h5>

      <p>
        Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE314579871
      </p>

      <h5 className={'title is-5'}>Disclaimer – rechtliche Hinweise</h5>

      <h6 className={'title is-6'}>§ 1 Warnhinweis zu Inhalten</h6>
      <p>
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt. Der
        Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der bereitgestellten
        kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge
        geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Allein durch den
        Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und
        dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des Anbieters.
      </p>
      <h6 className={'title is-6'}>§ 2 Externe Links</h6>
      <p>
        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der
        Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
        fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
        Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und
        auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
        hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist
        für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
      </p>
      <h6 className={'title is-6'}>§ 3 Urheber- und Leistungsschutzrechte</h6>
      <p>
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
        Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
        schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
        Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in
        Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist
        nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten
        und nicht kommerziellen Gebrauch ist erlaubt.
      </p>
      <p>
        Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
      </p>
      <h6 className={'title is-6'}>§ 4 Besondere Nutzungsbedingungen</h6>
      <p>
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen,
        wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall
        die besonderen Nutzungsbedingungen.
      </p>
      <p>
        Quelle: <a href={'https://www.juraforum.de/impressum-generator/'}> Impressum Muster von JuraForum.de</a>
      </p>
    </SingleCardLayout>
  )
}

export default ImprintPage
